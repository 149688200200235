<!--
Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
Copyright (C) 2024 HTWKalender support@htwkalender.de

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<script lang="ts" setup>
import { Ref, computed, inject, ref } from "vue";
import moduleStore from "../store/moduleStore";
import { useI18n } from "vue-i18n";

const dialogVisible: Ref<boolean> = ref(false);
const mobilePage = inject("mobilePage") as Ref<boolean>;
const { t } = useI18n({ useScope: "global" });

const store = moduleStore();
const tableData = computed(() =>
  store.getAllModules().map((module) => {
    return {
      Course: module.course,
      Module: module.userDefinedName,
    };
  }),
);

const previewOn: Ref<boolean> = computed(() => {
  return moduleStore().modules.size > 0;
});

const columns = computed(() => [
  { field: "Course", header: t("calendarPreview.course") },
  { field: "Module", header: t("calendarPreview.module") },
]);
</script>

<template>
  <Dialog
    id="calendar-dialog"
    ref="calendar"
    :visible="dialogVisible && previewOn"
    :maximizable="!mobilePage"
    :draggable="false"
    :header="$t('calendarPreview.preview-long')"
    class="w-full lg:w-30rem lg:h-auto m-0 lg:m-2"
    position="bottomleft"
    @update:visible="dialogVisible = $event"
  >
    <DataTable
      :value="tableData"
      edit-mode="cell"
      table-class="editable-cells-table"
      responsive-layout="scroll"
      class="w-full"
      :size="mobilePage ? 'small' : 'large'"
    >
      <Column
        v-for="column in columns"
        :key="column.field"
        :field="column.field"
        :header="column.header"
        :row-editor="false"
      >
        <template #body="{ data, field }">
          {{ data[field] }}
        </template>
      </Column>
    </DataTable>
  </Dialog>
  <SpeedDial
    v-if="previewOn && !dialogVisible"
    :style="{ position: 'fixed', bottom: '2rem', left: '2rem' }"
  >
    <template #button>
      <Button
        icon="pi pi-calendar"
        :label="$t('calendarPreview.preview')"
        class="p-button-rounded p-button-primary"
        raised
        @click="dialogVisible = true"
      />
    </template>
  </SpeedDial>
</template>

<style scoped></style>
