//Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
//Copyright (C) 2024 HTWKalender support@htwkalender.de

//This program is free software: you can redistribute it and/or modify
//it under the terms of the GNU Affero General Public License as published by
//the Free Software Foundation, either version 3 of the License, or
//(at your option) any later version.

//This program is distributed in the hope that it will be useful,
//but WITHOUT ANY WARRANTY; without even the implied warranty of
//MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
//GNU Affero General Public License for more details.

//You should have received a copy of the GNU Affero General Public License
//along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { Module } from "../model/module.ts";
import { defineStore } from "pinia";

const moduleStore = defineStore("moduleStore", {
  state: () => ({
    modules: new Map<string, Module>(),
  }),
  actions: {
    addModule(module: Module) {
      this.modules.set(module.uuid, module);
    },
    removeModule(module: Module) {
      this.modules.delete(module.uuid);
    },
    hasModule(module: Module): boolean {
      return (
        this.modules.has(module.uuid) &&
        (this.modules.get(module.uuid)?.isEqual(module) ?? false)
      );
    },
    removeAllModules() {
      this.modules.clear();
    },
    overwriteModules(modules: Module[]) {
      this.modules.clear();
      modules.forEach((module) => {
        this.modules.set(module.uuid, module);
      });
    },
    isEmpty(): boolean {
      return this.modules.size === 0;
    },
    countModules(): number {
      return this.modules.size;
    },
    getAllModules(): Module[] {
      return Array.from(this.modules.values());
    },
    containsModule(module: Module): boolean {
      return this.modules.has(module.uuid);
    },
  },
});

export default moduleStore;
