<!--
Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
Copyright (C) 2024 HTWKalender support@htwkalender.de

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<script lang="ts" setup>
import { computed } from "vue";
import localeStore from "../store/localeStore.ts";
import { useI18n } from "vue-i18n";
import { usePrimeVue } from "primevue/config";
import primeVue_de from "@/i18n/translations/primevue/prime_vue_local_de.json";
import primeVue_en from "@/i18n/translations/primevue/prime_vue_local_en.json";
const { t } = useI18n({ useScope: "global" });

const countries = computed(() => [
  { name: t("english"), code: "en", icon: "🇬🇧" },
  { name: t("german"), code: "de", icon: "🇩🇪" },
]);

function displayIcon(code: string) {
  return countries.value.find((country) => country.code === code)?.icon;
}

function displayCountry(code: string) {
  return countries.value.find((country) => country.code === code)?.name;
}

const primeVueConfig = usePrimeVue();

function updateLocale(locale: string) {
  localeStore().setLocale(locale);

  if (locale === "de") {
    primeVueConfig.config.locale = primeVue_de;
  } else {
    primeVueConfig.config.locale = primeVue_en;
  }
}

updateLocale(localeStore().locale);
</script>
<template>
  <Dropdown
    v-model="$i18n.locale"
    :options="$i18n.availableLocales"
    option-label="name"
    placeholder="Select a Language"
    class="w-full md:w-14rem"
    @change="updateLocale($event.value)"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex align-items-center">
        <div class="mr-2 flag">{{ displayIcon(slotProps.value) }}</div>
        <div>{{ displayCountry(slotProps.value) }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center">
        <div class="mr-2 flag">{{ displayIcon(slotProps.option) }}</div>
        <div>{{ displayCountry(slotProps.option) }}</div>
      </div>
    </template>
  </Dropdown>
</template>
