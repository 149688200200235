<!--
Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
Copyright (C) 2024 HTWKalender support@htwkalender.de

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { usePrimeVue } from "primevue/config";

const PrimeVue = usePrimeVue();

const emit = defineEmits(["dark-mode-toggled"]);

const isDark = ref(false);
const darkTheme = ref("lara-dark-blue"),
  lightTheme = ref("lara-light-blue");

function toggleTheme() {
  isDark.value = !isDark.value;
  setTheme(isDark.value);
}

function setTheme(shouldBeDark: boolean) {
  isDark.value = shouldBeDark;
  const newTheme = isDark.value ? darkTheme.value : lightTheme.value,
    oldTheme = isDark.value ? lightTheme.value : darkTheme.value;
  PrimeVue.changeTheme(oldTheme, newTheme, "theme-link", () => {});
  emit("dark-mode-toggled", isDark.value);
}

onMounted(() => {
  // set theme matching browser preference
  setTheme(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
  );

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (e) => {
      setTheme(e.matches);
    });
});
</script>

<template>
  <Button
    id="dark-mode-switcher"
    size="small"
    class="p-button-rounded w-full md:w-auto"
    style="margin-right: 1rem"
    :severity="isDark ? 'warning' : 'success'"
    @click="toggleTheme()"
  >
    <i v-if="isDark" class="pi pi-sun"></i>
    <i v-else class="pi pi-moon"></i>
  </Button>
</template>
