//Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
//Copyright (C) 2024 HTWKalender support@htwkalender.de

//This program is free software: you can redistribute it and/or modify
//it under the terms of the GNU Affero General Public License as published by
//the Free Software Foundation, either version 3 of the License, or
//(at your option) any later version.

//This program is distributed in the hope that it will be useful,
//but WITHOUT ANY WARRANTY; without even the implied warranty of
//MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
//GNU Affero General Public License for more details.

//You should have received a copy of the GNU Affero General Public License
//along with this program.  If not, see <https://www.gnu.org/licenses/>.

import {
  createMemoryHistory,
  RouterOptions,
  createWebHistory,
} from "vue-router";

const CourseSelection = () => import("../view/CourseSelection.vue");
const AdditionalModules = () => import("../view/create/AdditionalModules.vue");
const CalendarLink = () => import("../view/CalendarLink.vue");
const RenameModules = () => import("../view/create/RenameModules.vue");
const RoomFinder = () => import("../view/rooms/RoomFinder.vue");
const FreeRooms = () => import("../view/rooms/FreeRooms.vue");
const EditCalendarView = () => import("../view/edit/EditCalendar.vue");
const EditAdditionalModules = () =>
  import("../view/edit/EditAdditionalModules.vue");
const EditModules = () => import("../view/edit/EditModules.vue");
const FaqView = () => import("../view/FaqView.vue");

const routes: RouterOptions = {
  history: import.meta.env.SSR
    ? createMemoryHistory(import.meta.env.BASE_URL)
    : createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "course-selection",
      component: CourseSelection,
      meta: {
        label: "",
      },
    },
    {
      path: "/rooms/occupancy",
      name: "room-schedule",
      component: RoomFinder,
      meta: {
        label: "roomFinderPage.roomSchedule",
        description: "roomFinderPage.description",
      },
    },
    {
      path: "/rooms/free",
      name: "free-rooms",
      component: FreeRooms,
      meta: {
        label: "freeRooms.freeRooms",
        description: "freeRooms.description",
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: FaqView,
      meta: {
        label: "faq",
        description: "faqView.description",
      },
    },
    {
      path: "/additional-modules",
      name: "additional-modules",
      component: AdditionalModules,
      meta: {
        label: "createCalendar",
      },
    },
    {
      path: "/edit-additional-modules",
      name: "edit-additional-modules",
      component: EditAdditionalModules,
      meta: {
        label: "editCalendar",
        description: "editCalendarView.description",
      },
    },
    {
      path: "/edit-calendar",
      name: "edit-calendar",
      component: EditModules,
      meta: {
        label: "editCalendar",
        description: "editCalendarView.description",
      },
    },
    {
      path: "/calendar-link",
      name: "calendar-link",
      component: CalendarLink,
      meta: {
        label: "createCalendar",
      },
    },
    {
      path: "/edit",
      name: "edit",
      component: EditCalendarView,
      meta: {
        label: "editCalendar",
        description: "editCalendarView.description",
      },
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: {},
      meta: {
        label: "privacy",
        redirect:
          "https://www.htwk-leipzig.de/hochschule/kontakt/datenschutzerklaerung/",
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: {},
      meta: {
        label: "imprint",
        redirect: "https://www.htwk-leipzig.de/hochschule/kontakt/impressum/",
      },
    },
    {
      path: "/rename-modules",
      name: "rename-modules",
      component: RenameModules,
      meta: {
        label: "createCalendar",
      },
    },
  ],
};

export default routes;
