//Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
//Copyright (C) 2024 HTWKalender support@htwkalender.de

//This program is free software: you can redistribute it and/or modify
//it under the terms of the GNU Affero General Public License as published by
//the Free Software Foundation, either version 3 of the License, or
//(at your option) any later version.

//This program is distributed in the hope that it will be useful,
//but WITHOUT ANY WARRANTY; without even the implied warranty of
//MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
//GNU Affero General Public License for more details.

//You should have received a copy of the GNU Affero General Public License
//along with this program.  If not, see <https://www.gnu.org/licenses/>.

import "source-sans/source-sans-3.css";

import { ViteSSG } from "vite-ssg";
import "./style.css";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Card from "primevue/card";
import DataView from "primevue/dataview";
import Dialog from "primevue/dialog";
import Slider from "primevue/slider";
import ToggleButton from "primevue/togglebutton";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import routes from "./router";
import SpeedDial from "primevue/speeddial";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tag from "primevue/tag";
import { createPinia } from "pinia";
import MultiSelect from "primevue/multiselect";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import DynamicDialog from "primevue/dynamicdialog";
import DialogService from "primevue/dialogservice";
import ProgressSpinner from "primevue/progressspinner";
import Checkbox from "primevue/checkbox";
import Skeleton from "primevue/skeleton";
import Calendar from "primevue/calendar";
import i18n from "./i18n";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { Router } from "vue-router";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

polyfillCountryFlagEmojis();

let router: Router;

export const createApp = ViteSSG(
  App,
  {
    base: import.meta.env.BASE_URL,
    routes: routes.routes,
  },
  (ctx) => {
    const { app } = ctx;
    const pinia = createPinia();
    app.use(pinia);

    router = ctx.router;

    router.beforeEach(async (to, from) => {
      if (import.meta.env.SSR) {
        return;
      }

      // External redirect
      if (to.matched.some((record) => record.meta.redirect)) {
        window.location.replace(to.meta.redirect as string);
        return;
      }

      const newLocale = to.params.locale;
      const prevLocale = from.params.locale;
      // If the locale hasn't changed, do nothing
      if (newLocale === prevLocale) {
        return;
      }
      i18n.setLocale(newLocale);
    });

    app.use(VueQueryPlugin, {
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      },
    });

    app.use(router);
    app.use(ToastService);
    app.use(DialogService);
    i18n.setup();
    app.use(i18n.vueI18n);

    app.use(PrimeVue);
    app.component("Avatar", Avatar);
    app.component("Badge", Badge);
    app.component("Button", Button);
    app.component("Menu", Menu);
    app.component("Menubar", Menubar);
    app.component("Dialog", Dialog);
    app.component("Dropdown", Dropdown);
    app.component("InputText", InputText);
    app.component("InputSwitch", InputSwitch);
    app.component("Card", Card);
    app.component("DataView", DataView);
    app.component("Slider", Slider);
    app.component("ToggleButton", ToggleButton);
    app.component("SpeedDial", SpeedDial);
    app.component("TabView", TabView);
    app.component("TabPanel", TabPanel);
    app.component("MultiSelect", MultiSelect);
    app.component("Tag", Tag);
    app.component("Toast", Toast);
    app.component("Accordion", Accordion);
    app.component("AccordionTab", AccordionTab);
    app.component("DataTable", DataTable);
    app.component("Column", Column);
    app.component("DynamicDialog", DynamicDialog);
    app.component("ProgressSpinner", ProgressSpinner);
    app.component("Checkbox", Checkbox);
    app.component("Skeleton", Skeleton);
    app.component("Calendar", Calendar);
  },
);

export { router };
